export default {
  h: 'السلام عليكم',
  ourMovement: 'حركتنا',
  freeWord: 'كلمة حرة',
  announcements: 'بيانات',
  'main-article': 'كلمة حرة',
  discussion: 'المنتدى',
  Archive: 'الأرشيف و الوثائق',
  'ya-sham': 'يا شام',
  Arts: 'أريج الشام',
  library: 'المكتبة',
  reports: 'التقارير',
  'video-reportage': 'المرئيات',
  'Previous Post': 'المنشور التالي',
  'Next Post': 'المنشور السابق',
  book: 'الكتب',
  video: 'الصوتيات و المرئيات',
  report: 'دراسات و تقارير',
  mokh: 'مختارات',
  gate: 'بوابة',
  sounds: 'صوتيات',
  latestAnnounc: 'أحدث البيانات',
  latestBooks: 'أحدث الكتب',
  latestArticles: 'أحدث المقالات',
  welcomeToSOMM: ' أهلاً بكم في الموقع الرسمي لحركة سورية الأم',
  'Featured Articles': 'مختارات',
  Featured: 'مختارات',
  connectus: 'للتواصل معنا',

  omsFooter: 'حركة سورية الأم',
  featuredVideo: 'مقطع مختار',
  shareArticle: 'مشاركة :',
  footerText: ` حركة وطنية مجتمعية ذات تأثير سياسي ، وتوجه نابع من الفكر
                    السوري الوسطي ، تسعى لإنقاذ سورية وبنائها ، وتعزيز الحاضنة
                    الوطنية ، وهدم الاستبداد ، و تعبر عن طموح الشعب السوري في
                    بناء دولة المواطنة وسيادة القانون ، وتحقيق آماله في الحرية
                    والكرامة . تؤمن الحركة بأن سورية كنز إنساني ، وأن الشعب
                    السوري شعب حي عريق وله دور حضاري تجديدي وفريد.`
}
