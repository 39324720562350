import React from 'react'

import { Link } from 'gatsby'
import MyContext from '../components/Context'
import { TwitterTimelineEmbed, TwitterFollowButton } from 'react-twitter-embed'
import AwesomeSlider from 'react-awesome-slider'
import withAutoplay from 'react-awesome-slider/dist/autoplay'
import 'react-awesome-slider/dist/styles.css'
const AutoplaySlider = withAutoplay(AwesomeSlider)

function SideBarSection({ books, posts }) {
  return (
    <MyContext.Consumer>
      {cons => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1
          }}
        >
          {posts.filter(
            item =>
              item.frontmatter.lang == cons.i18n.language &&
              item.frontmatter.categories == 'main-article'
          ).length > 0 && (
            <div className="container sidebarSectionItem">
              <div
                style={{
                  borderBottom: '1px solid #b1afaf',
                  width: '100%',
                  marginBottom: '10px',
                  padding: '5px'
                }}
              >
                <h2 style={{ textAlign: 'center', margin: 0 }}>
                  {cons.strings('latestArticles')}
                </h2>
              </div>

              <div
                //                     className="PostCard"
                style={{
                  // height: '400px',
                  display: 'flex',

                  padding: '15px',

                  overflow: 'hidden',
                  minHeight: '300px'
                }}
              >
                <AutoplaySlider
                  play={true}
                  cancelOnInteraction={false} // should stop playing on user interaction
                  interval={3000}
                  bullets={false}
                  organicArrows={false}
                  startup={true}
                >
                  {posts
                    .filter(
                      item =>
                        item.frontmatter.lang == cons.i18n.language &&
                        item.frontmatter.categories == 'main-article'
                    )
                    .slice(0, 4)
                    .map((ii, index) => (
                      <div
                        key={index}
                        style={{
                          backgroundColor: 'white',
                          fontWeight: 'bold',
                          // height: '400px',
                          width: '100%'
                        }}
                      >
                        <Link
                          className="PostCard"
                          style={{
                            color: 'black',
                            textDecoration: 'none'
                          }}
                          to={ii.fields.slug}
                        >
                          {ii.frontmatter.featuredImage && (
                            <div className="">
                              <img
                                //   background
                                style={{ width: '100%', height: '180px' }}
                                src={ii.frontmatter.featuredImage}
                                alt={ii.frontmatter.title}
                              />
                            </div>
                          )}
                          <p style={{}}>{ii.frontmatter.title}</p>
                          {!ii.frontmatter.featuredImage && (
                            <p
                              style={{
                                fontWeight: 'initial',
                                fontSize: '15px'
                              }}
                            >
                              {`${ii.frontmatter.excerpt} ....`}
                            </p>
                          )}
                        </Link>
                      </div>
                    ))}
                </AutoplaySlider>
              </div>
            </div>
          )}

          <div className="container sidebarSectionItem">
            <div
              style={{
                borderBottom: '1px solid #b1afaf',
                width: '100%',
                marginBottom: '10px',
                padding: '5px'
              }}
            >
              <h2 style={{ textAlign: 'center', margin: 0 }}>
                {cons.strings('latestAnnounc')}
              </h2>
            </div>
            <div
              // className="PostCard"
              style={{
                display: 'flex',
                //                       alignItems: 'center',
                //                       justifyContent: 'center',
                paddingRight: '15px',
                paddingLeft: '15px',

                overflow: 'hidden',
                minHeight: '300px'

                //               backgroundColor: 'red'
              }}
            >
              <AutoplaySlider
                play={true}
                cancelOnInteraction={false} // should stop playing on user interaction
                interval={3000}
                bullets={false}
                organicArrows={false}
                startup={true}
              >
                {posts
                  .filter(
                    item =>
                      item.frontmatter.lang == cons.i18n.language &&
                      item.frontmatter.categories == 'announcements'
                  )
                  .slice(0, 4)
                  .map((ii, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          backgroundColor: 'white',
                          fontWeight: 'bold'
                        }}
                      >
                        <Link
                          style={{
                            color: 'black',
                            textDecoration: 'none'
                          }}
                          to={ii.fields.slug}
                        >
                          {ii.frontmatter.featuredImage && (
                            <div className="">
                              <img
                                //   background
                                style={{ width: '100%', height: '180px' }}
                                src={ii.frontmatter.featuredImage}
                                alt={ii.frontmatter.title}
                              />
                            </div>
                          )}
                          <p>{ii.frontmatter.title}</p>

                          {!ii.frontmatter.featuredImage && (
                            <p
                              style={{
                                fontWeight: 'initial',
                                fontSize: '15px'
                              }}
                            >
                              {`${ii.frontmatter.excerpt} ....`}
                            </p>
                          )}
                        </Link>
                      </div>
                    )
                  })}
              </AutoplaySlider>
            </div>
          </div>

          {books && (
            <div className="container sidebarSectionContainer">
              <div
                style={{
                  borderBottom: '1px solid #b1afaf',
                  width: '100%',
                  marginBottom: '10px',
                  padding: '5px'
                }}
              >
                <h2
                  style={{
                    textAlign: 'center',
                    margin: 0
                  }}
                >
                  {cons.strings('latestBooks')}
                </h2>
              </div>

              <AutoplaySlider
                style={{
                  height: '350px',
                  borderRadius: '5px'
                }}
                play={true}
                cancelOnInteraction={false} // should stop playing on user interaction
                interval={3000}
                bullets={true}
                organicArrows={true}
                startup={true}
              >
                {books
                  .filter(oo => oo.libtype != 'video')
                  .reverse()
                  .slice(0, 4)
                  .map((item, index) => (
                    <div
                      key={index}
                      style={{
                        backgroundColor: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <img
                        style={{
                          height: '250px',
                          maxWidth: '200px'
                        }}
                        src={item.image}
                        alt={item.title}
                      />
                      <p
                        className="book-title"
                        style={{
                          width: '200px',

                          textAlign: 'center'
                        }}
                      >
                        {item.title}
                      </p>
                    </div>
                  ))}
              </AutoplaySlider>
            </div>
          )}
          <div
            className="container"
            style={{
              marginBottom: '30px',
              // border: '1px solid #b1afaf',
              borderRadius: '5px',
              marginRight: '0px',
              marginLeft: '0px',
              width: '100%',
              backgroundColor: 'white',
              border: '1px solid #b1afaf'
            }}
          >
            <TwitterTimelineEmbed
              sourceType="profile"
              screenName="MovementOms"
              autoHeight
              lang={cons.i18n.language == 'en' ? 'en' : 'ar'}
              options={{
                tweetLimit: '1',
                width: '100%'
              }}
              theme="light"
            ></TwitterTimelineEmbed>
            <div style={{ textAlign: 'center' }}>
              <TwitterFollowButton screenName="MovementOms" />
            </div>
          </div>

          <div className="container sidebarSectionContainer">
            <div
              style={{
                borderBottom: '1px solid #b1afaf',
                width: '100%',
                padding: '5px'
              }}
            >
              <h2
                style={{
                  textAlign: 'center',
                  margin: 0
                }}
              >
                {cons.strings('featuredVideo')}
              </h2>
            </div>

            <iframe
              title="side bar youtube clip"
              //                     src="https://www.youtube.com/embed/07AvMhpnDF4"
              src={'https://www.youtube.com/embed/MVzaq70grFI'}
              allow="autoplay; encrypted-media"
              allowFullScreen="allowfullscreen"
              width="600"
              height="400"
              frameBorder="0"
              style={{
                width: '100%',
                maxWidth: '500px',
                height: '250px',
                borderRadius: '5px'
              }}
            ></iframe>
          </div>
        </div>
      )}
    </MyContext.Consumer>
  )
}

export default SideBarSection
