import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Meta from './Meta'
import Nav from './Nav'
import Footer from './Footer'
import MyContext from './Context'
import { withTrans } from '../i18n/withTrans'
import 'modern-normalize/modern-normalize.css'
import './globalStyles.css'

import SideBarSection from '../components/SideBarSection'

const Layout = ({
  children,
  meta,
  title,
  t,
  i18n,
  spTitle,
  noAnalytics,
  metaFeaturedImage,
  isSinglePost,
  postLang,
  fromHome
}) => {
  return (
    <StaticQuery
      query={graphql`
        query IndexLayoutQuery {
          settingsYaml {
            siteTitle
            siteDescription
            googleTrackingId
            socialMediaCard {
              image
            }
          }
          allPosts: allMarkdownRemark(
            filter: { fields: { contentType: { eq: "postCategories" } } }
            sort: { order: DESC, fields: [frontmatter___date] }
          ) {
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  title
                }
              }
            }
          }

          books: markdownRemark(
            id: { eq: "044d2f7d-62dd-5768-98af-dc0234544979" }
          ) {
            frontmatter {
              books_ar {
                pdf_file
                image
                alt
                title
                libtype
              }
              books_en {
                pdf_file
                image
                alt
                title
                libtype
              }
            }
          }

          posts: allMarkdownRemark(
            filter: { fields: { contentType: { eq: "posts" } } }
            sort: { order: DESC, fields: [frontmatter___date] }
          ) {
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  lang
                  title
                  date
                  categories
                  featuredImage
                  excerpt
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { siteTitle, socialMediaCard, googleTrackingId } =
            data.settingsYaml || {},
          subNav = {
            posts: data.allPosts.hasOwnProperty('edges')
              ? data.allPosts.edges.map(post => {
                  return { ...post.node.fields, ...post.node.frontmatter }
                })
              : false
          }

        return (
          <MyContext.Provider
            value={{ name: 'test name', strings: t, i18n: i18n }}
          >
            <div>
              <Helmet
                defaultTitle={siteTitle}
                titleTemplate={`%s | ${
                  postLang == 'en' ? 'Our Mother Syria Movement' : siteTitle
                }`}
              >
                {spTitle || title}
                <link
                  href="https://ucarecdn.com"
                  rel="preconnect"
                  crossorigin
                />
                <link rel="dns-prefetch" href="https://ucarecdn.com" />
                {/* Add font link tags here */}
              </Helmet>
              <Meta
                googleTrackingId={googleTrackingId}
                absoluteImageUrl={
                  metaFeaturedImage
                    ? metaFeaturedImage
                    : socialMediaCard &&
                      socialMediaCard.image &&
                      socialMediaCard.image
                }
                {...meta}
                {...data.settingsYaml}
                spTitle={spTitle || ''}
                noAnalytics={noAnalytics || false}
                isSinglePost={isSinglePost}
              />
              <div style={{ direction: i18n.language == 'en' ? 'ltr' : 'rtl' }}>
                <div className="cc">
                  <Nav subNav={subNav} />
                </div>
                <div
                  className="section"
                  style={{
                    padding: '20px',
                    display: 'flex',

                    justifyContent: 'center'
                  }}
                >
                  <div
                    className="layoutContainer"
                    style={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      maxWidth: '1600px'
                      // flexWrap: 'wrap'

                      // alignSelf: 'center'
                    }}
                  >
                    <div
                      style={{
                        flex: 2,
                        display: 'flex'
                      }}
                    >
                      <div
                        className="container"
                        style={{
                          backgroundColor: 'white',
                          borderRadius: '5px',

                          direction: i18n.language == 'en' ? 'ltr' : 'rtl'
                        }}
                      >
                        <Fragment>{children}</Fragment>
                      </div>
                    </div>
                    {!isSinglePost && (
                      <div
                        className="container cc"
                        style={{ display: 'flex', flex: 1, maxWidth: '400px' }}
                      >
                        <SideBarSection
                          books={
                            data.books ? data.books.frontmatter.books_ar : null
                          }
                          posts={data.posts.edges.map(item => item.node)}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="cc">
                  <Footer />
                </div>
              </div>
            </div>
          </MyContext.Provider>
        )
      }}
    />
  )
}
export default withTrans(Layout)
