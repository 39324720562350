import React from 'react'
import './Logo.css'

export default () => {
  return (
    <img
      style={{
        height: `145px`,
        marginTop: '7px',
        marginBottom: '-1px'
      }}
      src={'/images/ee.png'}
      alt="Logo"
    />
  )
}
