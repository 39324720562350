import React from 'react'
import MyContext from './Context'
import { Link } from 'gatsby'

import { FaTwitter } from 'react-icons/fa'
import { AiOutlineMail } from 'react-icons/ai'

import './Footer.css'

export default props => {
  return (
    <MyContext.Consumer>
      {value => (
        <div>
          <footer className="footer">
            <div className="container taCenter">
              {/* <h2 className="taCenter">social media here</h2> */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap-reverse'
                  // alignItems: 'center'
                }}
              >
                <div style={{ display: 'flex' }}>
                  <div>
                    <img
                      style={{
                        height: `170px`
                      }}
                      src={'/images/ee.png'}
                      alt="Logo"
                    />
                    <p style={{ fontSize: '15px', fontWeight: 'bolder' }}>
                      حركة سورية الأم - الموقع الرسمي
                      <br />
                      <a href="mailto: info@omsyrm.com">
                        <span style={{}}>{value.strings('connectus')}</span> :
                        info@omsyrm.com
                      </a>
                    </p>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <a href="mailto: info@omsyrm.com">
                        <AiOutlineMail
                          style={{
                            fontSize: '30px',
                            margin: '0px 5px',
                            // color: 'gray',
                            color: '#1877f2'
                          }}
                        />
                      </a>
                      <a
                        href="https://twitter.com/MovementOms"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaTwitter
                          style={{
                            fontSize: '30px',
                            margin: '0px 5px',
                            // color: 'gray',
                            color: '#00acee'
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignSelf: 'center',
                    flexDirection: 'column',
                    flex: 1
                    // flexWrap: 'wrap-reverse'
                  }}
                >
                  <p
                    style={{
                      fontSize: '16px',
                      lineHeight: 2,
                      marginBottom: '50px'
                    }}
                  >
                    <span style={{ fontWeight: 'bold' }}>
                      {`${value.strings('omsFooter')} `}
                    </span>
                    {value.strings('footerText')}
                  </p>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-around' }}
                  >
                    {[
                      {
                        link: '/',
                        label: 'ourMovement'
                      },
                      {
                        link: '/post-categories/main-article/',
                        label: 'freeWord'
                      },
                      {
                        link: '/post-categories/announcements/',
                        label: 'announcements'
                      },
                      {
                        link: '/post-categories/featured/',
                        label: 'mokh'
                      },
                      {
                        link: '/library/',
                        label: 'library'
                      },
                      {
                        link: '/areeg/',
                        label: 'Arts'
                      },
                      { link: '/gate/', label: 'gate' }
                    ].map((item, index) => (
                      <Link key={index} to={item.link}>
                        <span
                          key={index}
                          style={{ fontSize: '14px', color: '#41942f' }}
                        >
                          {value.strings(item.label)}
                        </span>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>

              <span style={{ marginBottom: '5px' }}>
                © Copyright {new Date().getFullYear()}
                <a href="https://omsyrm.com/">{` Our Mother Syria Movement , `}</a>
                All rights reserved.
              </span>
            </div>
          </footer>
        </div>
      )}
    </MyContext.Consumer>
  )
}
