export default {
  h: 'Hello World!',
  ourMovement: 'Our Movement',
  freeWord: 'Main Article',
  announcements: 'Announcements',
  'main-article': 'Main Article',
  discussion: 'discussion',
  Archive: 'Archive & Documents',
  'ya-sham': 'Ya Sham',
  Arts: 'Arts Treasures',
  library: 'Library',
  reports: 'Reports',
  'video-reportage': 'Video Reportage',
  'Previous Post': 'Previous Post',
  'Next Post': 'Next Post',
  book: 'books',
  video: 'videos',
  report: 'reports',

  mokh: 'Featured Articles',
  'Featured Articles': 'Featured',
  Featured: 'Featured Articles',
  gate: 'Web Gate',
  latestAnnounc: 'Latest Announcements',
  latestBooks: 'Latest Books',
  latestArticles: 'Latest Articles',
  welcomeToSOMM:
    'Welcome to the Offecial Website of  Our Mother Syria Movement',
  'Featured Articles': 'Featured Articles',
  omsFooter: 'Our Mother Syria Movement',
  connectus: 'Contact us',
  featuredVideo: 'Featured Video',
  shareArticle: 'Share',
  footerText:
    'is a national, societal movement, with political influence and an orientation that derives from moderate Syrian ethics. It seeks to save and build Syria, strengthen Syria’s national habitat homeland and topple tyrannical rule. The movement demonstrates the thoughts and ambitions of the Syrian people in building a state, establishing a rule of law, and attaining their right of freedom and dignity.'
}
