import i18next from 'i18next'
import ar from '../locales/ar'
import en from '../locales/en'
i18next.init({
  fallbackLng: 'ar',
  lng: 'ar',
  resources: {
    ar: {
      translations: ar
    },
    en: {
      translations: en
    }
  },
  ns: ['translations'],
  defaultNS: 'translations',
  returnObjects: true,
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false // not needed for react!!
  },
  react: {
    wait: true
  }
})

i18next.languages = ['ar', 'en']
i18next.on('loaded', function(loaded) {
  console.log('llllllllll')
})

export default i18next
